<template>
  <div>
    <t-header />
    <main>
      <!-- <img :src="require('@/assets/royce_hall.jpg')" /> -->
      <div class="container-landing">
        <div
          class="space-bottom-lg-1 space-bottom-0 position-relative mb-0 pt-0 mt-5 mt-xl-0"
        >
          <div class="row align-items-center">
            <div class="col-lg-6 col-xl-6 mb-3 mb-md-10">
              <div class="row pl-1">
                <div class="col">
                  <div class="mb-5">
                    <h1 class="mb-4" style="font-size: 36px">
                      Tax Filing for U.S. Nonresidents
                    </h1>
                    <p class="home-lead mb-2">
                      Does your U.S. visa type start with an F, J, M, Q or H?
                      Thriftax is the easiest and most accurate way to file your
                      taxes.
                    </p>
                    <p class="home-lead mb-3">
                      Many nonresidents don't know that they need to send tax
                      forms to the IRS – regardless of income status.
                    </p>
                  </div>

                  <div
                    class="row pr-3 pr-md-10 pr-xl-11 pl-3 align-items-end buttons_row mb-3 mt-sm-0"
                  >
                    <div class="col-12 col-sm-6 col-md-4 pr-sm-2 pr-md-0 p-0">
                      <btn
                        @click="$router.push({ name: 'Signup' })"
                        block
                        class="transition-3d-hover py-3"
                        >Get Started</btn
                      >
                    </div>
                    <div
                      class="col-12 col-sm-6 col-md-4 mt-3 pl-sm-2 pl-md-0 p-0 ml-md-5"
                    >
                      <btn
                        color=""
                        @click="
                          $smoothScroll({
                            scrollTo: $refs.threeStep,
                            duration: 1000,
                            offset: -100
                          })
                        "
                        block
                        class="transition-3d-hover  py-3 btn-outline-primary"
                        >Learn more</btn
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-xl-6 m-0 p-0 d-flex justify-content-end">
              <figure class="col-lg-12 d-none d-lg-block pr-0 ie-main-hero">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 1137.5 979.2"
                >
                  <path
                    fill="#F9FBFF"
                    d="M565.5,957.4c81.1-7.4,155.5-49.3,202.4-115.7C840,739.8,857,570,510.7,348.3C-35.5-1.5-4.2,340.3,2.7,389c0.7,4.7,1.2,9.5,1.7,14.2l29.3,321c14,154.2,150.6,267.8,304.9,253.8L565.5,957.4z"
                  />
                  <defs>
                    <path
                      id="mainHeroSVG1"
                      d="M1137.5,0H450.4l-278,279.7C22.4,430.6,24.3,675,176.8,823.5l0,0C316.9,960,537.7,968.7,688.2,843.6l449.3-373.4V0z"
                    />
                  </defs>
                  <clipPath id="mainHeroSVG2">
                    <use xlink:href="#mainHeroSVG1" />
                  </clipPath>
                  <g
                    transform="matrix(1 0 0 1 0 0)"
                    clip-path="url(#mainHeroSVG2)"
                  >
                    <image
                      width="1000"
                      height="660"
                      xlink:href="../../assets/royce_hall.jpg"
                      transform="matrix(1.4462 0 0 1.4448 52.8755 0)"
                    ></image>
                  </g>
                </svg>
              </figure>
            </div>
          </div>
        </div>
        <div class="space-bottom-md-3 space-bottom-1">
          <div class="mt-3" ref="threeStep"></div>
          <div class="container step-border-last-0 mb-0 mb-md-5">
            <ThreeStep />
          </div>
          <div class="d-none d-sm-flex justify-content-center">
            <btn
              color=""
              @click="
                $smoothScroll({
                  scrollTo: $refs.pricingDetails,
                  duration: 1000,
                  offset: -100
                })
              "
              class="transition-3d-hover py-3 btn-outline-primary"
              >What forms do I need to file?</btn
            >
          </div>
        </div>

        <div ref="pricingDetails"></div>
        <div class="px-lg-4">
          <div class="w-md-80 w-lg-60 text-center mx-md-auto mb-3 pb-0">
            <h3 class="h2">
              Certainty about which forms to file
            </h3>
            <p class="mb-0">
              As you file with us, we'll automatically determine which forms
              you're required to file. The types of forms that you may need to
              file as a non-resident are listed below.
            </p>
          </div>
          <PricingCards />
        </div>

        <div class="container px-lg-7 mb-3 mt-md-4 mt-2">
          <CoverageMessage />
        </div>

        <h1 class="display-5 text-center mb-3">Why Thriftax?</h1>

        <div class="container">
          <div class="col-lg-10 d-flex mx-auto">
            <IntroVideo />
          </div>
        </div>

        <div class="d-flex justify-content-center px-3"></div>
        <div class="mt-4">
          <div class="space-top-xl-2 space-bottom-xl-2 space-top-1">
            <div class="px-4 w-xl-80 mx-xl-auto">
              <div class="row justify-content-lg-center align-items-md-center">
                <div class="col-md-6 mb-7 mb-md-0">
                  <HeroBullets />
                </div>

                <div class="col-md-6 col-lg-5">
                  <div class="position-relative">
                    <div class="card" data-aos="fade-left" data-aos-delay="100">
                      <div class="card-body">
                        <figure class="w-100 max-w-8rem mb-4">
                          <img
                            class="img-fluid"
                            src="@/assets/svg/icons/icon-34.svg"
                            alt="SVG"
                          />
                        </figure>
                        <h3>
                          A tailored experience made for you
                        </h3>
                        <p>
                          Every international student needs to send tax forms to
                          the IRS, whether it be form 8843 for those with no
                          income, or a full tax return for those with an income.
                          So we've made it easy by providing all the information
                          you'll need and by asking you only what's relevant to
                          your situation.
                        </p>
                      </div>
                    </div>

                    <figure
                      class="max-w-19rem w-100 position-absolute bottom-0 right-0 z-index-n1"
                    >
                      <div class="mb-n7 mr-n7">
                        <img
                          class="img-fluid"
                          src="../../assets/svg/components/dots-2.svg"
                          alt="Image Description"
                        />
                      </div>
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <t-footer />
    </main>
  </div>
</template>

<script>
import ThreeStep from "@/components/landings/three-step";
import CoverageMessage from "@/components/landings/coverage-message";
import IntroVideo from "@/components/landings/intro-video";
import HeroBullets from "@/components/landings/hero-bullets";
import PricingCards from "@/components/landings/pricing-cards";
import AOS from "aos";
// import "aos/dist/aos.css";
const TOKEN_LIFETIME_LEFT_INITIAL = 1.5 * 60 * 60;

export default {
  components: {
    ThreeStep,
    CoverageMessage,
    IntroVideo,
    HeroBullets,
    PricingCards
  },
  data() {
    return {};
  },

  mounted() {
    AOS.init({
      once: true
    });
  },
  created() {
    // if (this.$store.getters.isAuthenticated(TOKEN_LIFETIME_LEFT_INITIAL)) {
    // 	this.$router.push({ name: "Dashboard" });
    // }
  },
  metaInfo: {
    title:
      "Thriftax: Nonresident Online Tax Filing Software - 1040NR and State",
    meta: [
      {
        name: "description",
        content: `The #1 nonresident tax filing software made for international students, scholars, researchers, and other foreign employees. Easily file federal (1040NR), 8843, state, and FICA tax returns. 

A nonresident tax filing solution for F1, J1, H1B, and other visa holders. Get your federal 1040NR, 8843, and state tax returns prepared in minutes.`
      }
    ]
  }
};
</script>

<style scoped>
.buttons_row {
  max-width: 800px;
}

.max-width-xl {
  max-width: 700px;
}

.body_container {
  /* width: 90%; */
  max-width: 1670px;
  margin: 0 auto;
}
.sub_header {
  font-size: 20.5px;
}
.smaller_text {
  font-size: 11.5px;
}
.very_small {
  font-size: 12px;
}
.padding_top {
  padding-top: 12.5px !important;
}

.responsive_width {
  max-width: 515px;
}

.absolute_position {
  position: absolute;
  top: 0px;
  right: 0px;
}
@media only screen and (max-width: 576px) {
  .responsive_width {
    max-width: 580px;
  }
}

@media only screen and (min-width: 1100px) {
  .responsive_width {
    max-width: 560px;
  }
}

@media only screen and (min-width: 1200px) {
  .responsive_width {
    max-width: 580px;
  }
}

@media only screen and (min-width: 1250px) {
  .responsive_width {
    max-width: 630px;
  }
}

@media only screen and (min-width: 1300px) {
  .responsive_width {
    max-width: 680px;
  }
}

@media only screen and (min-width: 1350px) {
  .responsive_width {
    max-width: 700px;
  }
}
</style>
